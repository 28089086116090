import { gql } from '@apollo/client';

export const CREATE_NEW_DISEASE = gql`
  mutation CreateDiseases($data: DiseasesInput!) {
    createDiseases(data: $data) {
      count
      diseases {
        createdAt
        id
        isFeatured
        name
        noOfAssessment
        order
        linkedTests {
          id
          title
        }
        questions {
          id
        }
        serialNumber
        thumbnail
        updatedAt
      }
    }
  }
`;

export const UPDATE_CURRENT_DISEASE = gql`
  mutation UpdateDiseasesAsAdmin($data: DiseasesInput!, $diseasesId: String!) {
    updateDiseasesAsAdmin(data: $data, diseasesId: $diseasesId) {
      diseases {
        createdAt
        id
        isFeatured
        name
        noOfAssessment
        order
        linkedTests {
          id
          title
        }
        questions {
          id
        }
        serialNumber
        thumbnail
        updatedAt
      }
    }
  }
`;

export const TOGGLE_FEATURE_DISEASE = gql`
  mutation ToggleDiseases($diseasesId: String!) {
    toggleDiseases(diseasesId: $diseasesId)
  }
`;

export const TOGGLE_FEATURE_DISEASE_CATEGORY = gql`
  mutation ToggleDiseasesCategory($toggleDiseasesCategoryId: String!) {
    toggleDiseasesCategory(id: $toggleDiseasesCategoryId)
  }
`;

export const TOGGLE_ACTION_DISEASE_CATEGORY = gql`
  mutation ToggleEnableDiseasesCategory($categoryId: String!) {
    toggleEnableDiseasesCategory(categoryId: $categoryId)
  }
`;

export const DELETE_DISEASE = gql`
  mutation RemoveDiseases($diseasesId: String!) {
    removeDiseases(diseasesId: $diseasesId)
  }
`;

export const CREATE_DISEASE_CATEGORY = gql`
  mutation CreateDiseasesCategory($data: DiseasesCategoryInput!) {
    createDiseasesCategory(data: $data) {
      diseasesCategory {
        id
        createdAt
        isDisabled
        # isFeatured
        linkedDiseases {
          id
          name
        }
        name
        order
        serialNumber
        # thumbnail
      }
    }
  }
`;

export const UPDATE_DISEASE_CATEGORY = gql`
  mutation UpdateDiseasesCategory(
    $data: DiseasesCategoryInput!
    $categoryId: String!
  ) {
    updateDiseasesCategory(data: $data, categoryId: $categoryId) {
      diseasesCategory {
        createdAt
        id
        isDisabled
        # isFeatured
        linkedDiseases {
          id
          name
        }
        name
        order
        serialNumber
        # thumbnail
      }
    }
  }
`;

export const ADD_DISEASE_QUESTION = gql`
  mutation AddQuestionToDiseases($data: AddQuestionToDiseasesInput!) {
    addQuestionToDiseases(data: $data) {
      diseases {
        id
        name
        questions {
          id
          optionType
          questionText
          options {
            id
            optionText
          }
        }
      }
    }
  }
`;

export const UPDATE_DISEASE_QUESTION = gql`
  mutation UpdateDiseasesQuestion($data: UpdateDiseasesQuestion!) {
    updateDiseasesQuestion(data: $data) {
      diseases {
        id
        name
        questions {
          id
          optionType
          questionText
          options {
            id
            optionText
          }
        }
      }
    }
  }
`;

export const REMOVE_QUESTION = gql`
  mutation RemoveQuestionToDiseases($data: RemoveQuestionToDiseasesInput) {
    removeQuestionToDiseases(data: $data) {
      diseases {
        id
        name
        questions {
          id
          optionType
          questionText
          options {
            id
            optionText
          }
        }
      }
    }
  }
`;

export const DISEASE_LINKED_TESTS = gql`
  mutation updateDiseasesLinkedTest($data: UpdateDiseasesLinkedTest!) {
    updateDiseasesLinkedTest(data: $data)
  }
`;

export const SAMPLE = gql`
  query {
    checkGraphQLStatus
  }
`;
